<div class="container-fluid">
    <div class="jumbotron jumbotron-fluid">
        <div class="row">
            <div class="col-6">
                <!-- <blockquote class="blockquote">
                    <p class="mb-0">{{title}}</p>
                    <p class="mt-0">{{reportName}}</p>
                </blockquote> -->
                <h2>
                    {{title}}
                    <small class="text-muted" [hidden]="reportName == ''"> > {{reportName}}</small>
                </h2>
                <!-- <p class="" ><span><span> >
                            </span></span>
                </p> -->
            </div>
            <div class="col-6 text-end mt-4">
                <!-- <button class="btn btn-outline-danger" type="button" (click)="deleteLast()">
                <b>Delete</b>
            </button> -->
                <button class="btn btn-outline-primary" type="button" [hidden]="isPreview"
                (click)="addOrUpdateCustomReport()">
                    <b>
                        Save <span class="spinner-border spinner-border-sm" *ngIf="saveUpdateLoader" role="status" aria-hidden="true"></span>
                    </b>
                </button> &nbsp;
                <button class="btn btn-outline-danger" type="button" (click)="close()">
                    <b>Close</b>
                </button> &nbsp;
                <button type="button" class="btn btn-outline-primary" [hidden]="this.addFieldFormGroup.value.id == -1" [disabled]="loading" (click)="exportExcel()">
                    <b>
                        Export <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
                    </b>
                </button>
                <button [title]="genertaedTime" class="control-buttons" (click)="getReportHistoryById()"
                    [hidden]="isDownloadLinkHidden" style="color: blue;" [disabled]="isDownloadLink">
                    <button class="btn" style="border: 1px solid #0d6efd; color: #0d6efd;"><i class="fa fa-download"
                            aria-hidden="true"><span class="spinner-border spinner-border-sm" *ngIf="isDownloadLink"
                                role="status" aria-hidden="true"></span></i></button>
                </button>
                <!-- <button type="button" class="btn btn-outline-primary" (click)="exportExcel()"
                    [disabled]="exportLoading">
                    <b>
                        Export <span class="spinner-border spinner-border-sm" *ngIf="exportLoading" role="status"
                            aria-hidden="true"></span>
                    </b>
                </button>
                <button type="button" [title]="genertaedTime" title="df" class="control-buttons blue-color"
                    (click)="getReportHistoryById()" [hidden]="isDownloadLinkEnable" [disabled]="isDownloadLink">
                    <button type="button" class="btn btn-dwnlod" title="df"><i class="fa fa-download"
                            aria-hidden="true"><span class="spinner-border spinner-border-sm" *ngIf="isDownloadLink"
                                role="status" aria-hidden="true"></span></i></button>
                </button> -->
            </div>
        </div>
    </div>
    <div class="row" [formGroup]="addFieldFormGroup" [hidden]="isPreview">
        <div class="row mb-4">
            <div class="col-2">
                <label for="customReportName">
                    Name
                </label>
            </div>
            <div class="col-3">
                <input id="customReportName" type="text" class="form-control custom-pages-input" formControlName="name">
            </div>
        </div>
        <!-- <div class="row mb-2">
            uncomment this code to add generated by 
            <div class="col-2" >
                Generated by
            </div>
            <div class="col-1 form-check" >
                <input id="tagRadioOption" class="form-check-input" type="radio" formControlName="generatedBy"
                [value]="1">
                <label for="tagRadioOption" class="form-check-label">Tag</label>
            </div>
            <div class="col-2 form-check" >
                <input id="dataPointRadioOption" class="form-check-input" type="radio" formControlName="generatedBy"
                [value]="2">
                <label for="dataPointRadioOption" class="form-check-label">Data Point</label>
            </div>
        </div> -->
        <div class="row">
            <div class="col-2 col-lg-1_5">
                <ng-multiselect-dropdown class="dropdown-list" placeholder="Visit" formControlName="visit"
                    [settings]="visitDropDownSettings" [data]="visitDropDownList" (onSelect)="onSelectVisit()"
                    (onSelectAll)="onSelectVisit()" (onDeSelect)="deSelectVisit()" (onDeSelectAll)="deSelectVisit()">
                </ng-multiselect-dropdown>
            </div>
            <div class="col-2 col-lg-1_5">
                <ng-multiselect-dropdown class="dropdown-list" placeholder="Form" formControlName="form"
                    [settings]="formDropDownSettings" [data]="formDropDownList" (onSelect)="onSelectForm()"
                    (onSelectAll)="onSelectForm()">
                </ng-multiselect-dropdown>
            </div>
            <div class="col-2 col-lg-1_5">
                <ng-multiselect-dropdown class="dropdown-list" placeholder="Field" formControlName="field"
                    [settings]="fieldDropDownSettings" [data]="fieldDropDownList">
                </ng-multiselect-dropdown>
            </div>
            <div class="col-1">
                <button type="button" title="Add" class="btn btn-outline-primary"
                    (click)="addNewField(addFieldFormGroup.value.form,addFieldFormGroup.value.field)">
                    <i class="plusSignIcon"></i>
                </button>
            </div>
            <div class="col-1">
                <button type="button" title="Add" class="btn btn-outline-primary" [hidden]="isPreview"
                (click)="previewWithData()">
                    <b>Preview</b>
                </button>
            </div>
        </div>
    </div>
    <hr>
    <div class="table-responsive">
        <table mat-table class="table table-hover table-light" id="table">
            <thead class="thead-light">
                <tr class="example-boundary" cdkDropList cdkDropListOrientation="horizontal"
                    [cdkDropListData]="tableHeaderData" (cdkDragReleased)="release=true"
                    (cdkDropListDropped)="dropCol($event)">
                    <ng-container *ngFor="let column of tableHeaderData, let i = index">
                        <th scope="col" cdkDragBoundary=".example-boundary" cdkDrag
                            [cdkDragDisabled]="i < 4 || isPreview " (mousedown)="mouseDown($event)" cdkDragLockAxis="x"
                            (cdkDragReleased)="release=true;">
                            <span style="position:relative;display:block;">
                                <span title="drag" class="example-handle" cdkDragHandle>
                                    {{column.label}}
                                </span>
                                <button style="position: absolute; top: -16px; right: -5px;" type="button"
                                    title="remove" class="btn-no-style" (click)="remove(column)">
                                    <i class="crossSignIcon" [hidden]="i < 4 || isPreview "></i>
                                </button>
                            </span>
                            <!-- <div *cdkDragPreview>
                                <div [style.width]="pos?.width" [style.margin-top]="pos?.y" [style.margin-left]="pos?.x" >
                                    <thead>
                                        <tr >
                                            <th >
                                                {{column.header}}
                                            </th>
                                        </tr>
                                    </thead>
                                </div>
                            </div>
                            <div class="placeholder" *cdkDragPlaceholder></div> -->
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody *ngIf="paginateData.length > 0 ; else noData">
                <tr #tr *ngFor="let element of paginateData">
                    <ng-container *ngFor="let cell of tableHeaderData">
                        <ng-container>
                            <td>{{element[cell.label]}}
                            </td>
                        </ng-container>
                    </ng-container>
                    <!-- <div class="preview">
                    <table >
                        <tr >
                            <td  *ngFor="let cell of tableHeaderData">{{element[cell.field]}}</td>
                        </tr>
                    </table>
                    </div>
                    <div class="preview placeholder"></div> -->
                </tr>
            </tbody>
            <tr *ngIf="paginateData.length > 0" class="tableFooterHr h-auto"></tr>
        </table>
        <div *ngIf="paginateData.length > 0">
            <ngb-pagination [collectionSize]="totalPages" [(page)]="page" [boundaryLinks]="true" [rotate]="true"
                [ellipses]="false" (pageChange)="onChangePagination()">
                <ng-template ngbPaginationPages let-page let-pages="pages">
                    <li class="ngb-custom-pages-item" *ngIf="pages.length > 0">
                        <div class="mb-3 d-flex flex-nowrap px-2">
                            <label id="paginationInputLabel" for="paginationInput"
                                class="col-form-label me-2 ms-1">Page</label>
                            <input #i type="text" inputmode="numeric" pattern="[0-9]*"
                                class="form-control custom-pages-input" id="paginationInput" [value]="page"
                                (keyup.enter)="selectPage(i.value)" (blur)="selectPage(i.value)"
                                (input)="formatInput($any($event).target)"
                                aria-labelledby="paginationInputLabel paginationDescription" style="width: 2.3em" />
                            <span id="paginationDescription" class="col-form-label text-nowrap px-2"> of
                                {{pages.length}}</span>
                        </div>
                    </li>
                </ng-template>
            </ngb-pagination>
        </div>
    </div>
    <ng-template #noData>
        <tr class="tableFooterHr h-auto border-bottom-0">
            <br>
            <h5>
                No Data Available! &nbsp;
            </h5>
        </tr>
    </ng-template>
</div>