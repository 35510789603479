<div class="modal-content">
	<div class="modal-header">
		<h3 class="modal-title">Data to be updated <span *ngIf="dataTypeId == 17">({{fieldName}})</span></h3>
		<button class="btn-close" id="queryModalClose" data-bs-dismiss="modal" aria-label="Close" (click)="closePopup()"></button>
	</div>
	<div class="modal-body">
		<div class="row" *ngIf="checkIsReasonCaptureData() == true">
			<div class="col-12 text-center">
				<p [style.color]="errorIconColor" style="font-size: 20px">
					Data points which are updated <span *ngIf="dataTypeId == 17">in Row - {{rowId}}</span> .
				</p>
			</div>
			<div class="table-responsive">
				<table id="updatingIssue" class="table table-hover table-light">
					<thead>
						<tr>
							<th>Sr.No</th>
							<th>Field Name</th>
							<th>Old Value</th>
							<th>New Value</th>
							<th>Reason</th>
							<th>Comment</th>
						</tr>
					</thead>
					<tbody [formGroup]="updateDataForm" *ngFor="let fld of dataEntryData.fieldsData;let i=index">
						<tr [formArrayName]="i" *ngIf="fld.isReasonCapture">
							<td>{{i+1}}</td>
							<td><span *ngIf="[18,17].includes(dataTypeId)">{{fld.colHeading}}</span><span *ngIf="![18,17].includes(dataTypeId)">{{fld.fieldName}}</span></td>
							<td>{{getOptionNameFromId(fld.oldValue,fld.id,fld.fieldId)}}</td>
							<td>{{getOptionNameFromId(fld.value,fld.id,fld.fieldId)}}</td>
							<td>
								<select class="form-select" [id]="'reason-'+fld.id" [name]="'reason-'+fld.id" formControlName="reason">
									<option [ngValue]="null" selected>--Select--</option>
									<option *ngFor="let reason of reasonData" [value]="reason">{{reason}}</option>
								</select>
							</td>
							<td>
								<input type="text" [id]="'comment-'+fld.id" [name]="'comment-'+fld.id" class="form-control" autocomplete="off" formControlName="comment" style="z-index: 1000 !important;">
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="row" *ngIf="getQueryToBeRaised.length > 0">
			<div class="col-12 text-center">
				<p [style.color]="blackColor" style="font-size: 20px">
					Queries to be raised.
				</p>
			</div>
			<div class="table-responsive">
				<table id="myTable" class="table table-hover table-light">
					<thead>
						<tr>
							<!-- <th *ngIf="rowId == -1">Sr.No</th> -->
							<th>Field Name</th>
							<th>Multi-Row Field</th>
							<th>Row ID</th>
							<th>Field Value</th>
							<th>Query Description</th>
							<th>Priority</th>
						</tr>
					</thead>
					<tbody *ngFor="let fld of getQueryToBeRaised;let i=index">
						<tr>
							<!-- <td *ngIf="rowId == -1">{{i+1}}</td> -->
							<td>{{fld.fieldName}}</td>
							<td>{{fld.multiRowName != null ? fld.multiRowName : ''}}</td>
							<td>{{fld.rowId != null && fld.rowId != -1 ? fld.rowId : '' }}</td>
							<td>{{fld.fieldValue}}</td>
							<td>{{fld.queryDesc}}</td>
							<td>{{fld.queryPriority != null ? queryPriority[fld.queryPriority - 1].name : ''}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<div class="container">
			<div class="row">
				<div class="col-8 mt-1">
					<p [style.color]="blackColor" *ngIf="getQueryToBeRaised.length > 0">
						Are you sure you want to continue with these Queries?
					</p>
				</div>
				<div class="col-4 text-end">
					<button class="btn btn-outline-danger" id="queryModalClose" data-bs-dismiss="modal" (click)="closePopup()" [disabled]="dataEntryLoading">
						<b>No</b>
					</button> &nbsp;
					<button class="btn btn-outline-primary" [hidden]="isLocked == 1" (click)="submitDataInApi()" [disabled]="isYesClicked || dataEntryLoading">
						<b>
							Yes <span class="spinner-border spinner-border-sm" *ngIf="dataEntryLoading" role="status" aria-hidden="true"></span>
						</b>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
