<div class="container">
	<canvas id="captcahCanvas" width="316" height="80"></canvas>
	<div class="row">
		<div class="col-9">
			<input class="form-control" type="text" [(ngModel)]="captch_input" (blur)="onDataEntered($event)" tabindex="1"/>
		</div>
		<div class="col-3">
			<a href="javascript:void(0)" class="cpt-btn" (click)="playCaptcha()">🔈</a>
			<a href="javascript:void(0)" class="cpt-btn reload" (click)="createCaptcha()">&#x21bb;</a>
			<!-- <input type="button" value="Check" (click)="checkCaptcha()" /> -->
			
		</div>
	</div>
</div>

<!-- <div class="captchaform">
	<canvas id="captcahCanvas" width="316" height="80" class="canvasSize"></canvas>
	<div class="captchaAnswerinput">
			<input class="form-control bg-transparent" type="text" [(ngModel)]="captch_input" (blur)="onDataEntered($event)" tabindex="1" style="border: 7px solid rgb(219 244 255); border-radius: 7px; width:235px; padding:2px;" />
		<div class="captchaRefresh">
			<a href="javascript:void(0)" class="cpt-btn speaker" (click)="playCaptcha()">🔈</a>
			<a href="javascript:void(0)" class="cpt-btn reload refresh-arrow" (click)="createCaptcha()">&#x21bb;</a>
			<! -- <input type="button" value="Check" (click)="checkCaptcha()" /> -- >
			
		</div>
	</div>
</div> -->
