import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CANCEL_MSG, DELETE_MSG } from 'src/app/constant/responseMessage';
import { UnitService } from 'src/app/services/unit.service';

@Component({
  selector: 'app-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.css'],
})
export class UnitsComponent implements OnInit {
  unitListData: any = [];
  indicationsData: any = [];
  title: string = 'Unit';
  isEdit: boolean = false;
  unitForm!: FormGroup;
  unitId: any;
  loading: boolean = false;
  constructor(
    private unitApiService: UnitService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {
    this.createUnitForm();
  }

  ngOnInit() {
    this.getUnitList();
  }

  createUnitForm() {
    this.unitForm = this.formBuilder.group({
      unit: ['', [Validators.required]],
    });
  }

  setStatus() {
    this.isEdit = false;
  }

  getUnitList() {
    this.unitApiService.getAllUnits().subscribe((unitList: any) => {
      console.log("RESPONSE :: ",unitList);
      if (unitList.responseObject) {
        this.unitListData = unitList.responseObject;
      }
    });
  }

  addUnit() {
    this.loading= true;
    this.unitApiService.saveUnits(this.unitForm.value).subscribe(
      (response: any) => {
        console.log("RESPONSE :: ",response);
        this.isEdit = false;
        this.closeModal();
        this.loading=false;
        this.toastr.success(response.message);
        this.getUnitList();
      },
      (error) => {
        this.isEdit = false;
        this.loading=false;
        this.unitForm.reset();
        this.toastr.error(error.message);
      }
    );
  }

  OnSelect(unitListData: any) {
    this.indicationsData = [];
    this.indicationsData = this.unitListData.find(
      (cntry: any) => cntry.unitId == unitListData.value
    ).indications;
  }

  closeModal() {
    // this.formValue.reset()
    this.unitForm.reset();
    let ref = document.getElementById('cancel');
    ref?.click();
  }

  editUnit(row: any, index: any) {
    this.isEdit = true;

    this.unitId = row.id;

    this.unitApiService.getUnitById(row.id).subscribe(
      (res: any) => {
        console.log("RESPONSE :: ",res);
        if (res.status != 0) {
          this.toastr.error(res.message);
        } else {
          if (res.responseObject) {
            this.unitForm.get('unit')?.setValue(res.responseObject.unit);
          }
        }
      },
      (err) => {
        this.toastr.error(err.message);
      }
    );
  }

  updateUnit() {
    this.unitForm.value;
    this.loading = true;
    if (this.unitForm.valid && this.unitForm.value) {
      let requestBody = {
        id: this.unitId,
        unit: this.unitForm.get('unit')?.value,
      };

      (requestBody.id = this.unitId),
        (requestBody.unit = this.unitForm.get('unit')?.value);

      this.unitApiService.updateUnit(requestBody).subscribe(
        (response: any) => {
          console.log("RESPONSE :: ",response);
          this.toastr.success(response.message);
          this.isEdit = false;
          this.loading = false;
          this.closeModal();
          this.getUnitList();
        },
        (error) => {
          this.isEdit = false;
          this.unitForm.reset();
          this.toastr.error(error.message);
          this.loading = false
        }
      );
    }
  }

  deleteUnit(row: any, index: any) {
    let r = confirm(DELETE_MSG);
    if (r == true) {
      this.unitApiService.deleteUnit(row.id).subscribe(
        (res: any) => {
          console.log("RESPONSE :: ",res);
          if (res.status != 0) {
            this.toastr.error(res.message);
          } else {
            this.toastr.success(res.message);
            if (this.unitListData.length) {
              this.unitListData.splice(index, 1);
            }
          }
        },
        (err) => {
          this.toastr.error(err.message);
        }
      );
    } else {
      this.toastr.error(CANCEL_MSG);
    }
  }
}
