import { Component, OnInit, Renderer2 } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CUSTOM_REPORT, DOWNLOAD_REPORT_STARTED } from "src/app/constant/responseMessage";
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE, EXPORT_TYPES, REPORT_INTERVAL_START_TIME, REPORTS_NAME } from "src/app/constant/constant";
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormControl, FormGroup } from '@angular/forms';
import { FormService } from 'src/app/services/form.service';
import { FieldService } from 'src/app/services/field.service';
import { distinctUntilChanged, forkJoin } from 'rxjs';
import { toArray } from 'lodash';
import { FilterJson, FilterJsonDTO, TableHeader, TableHeaderDTO } from 'src/app/constant/export-types';
import { CustomReportService } from '../../services/custom-report.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ReportsService } from 'src/app/services/reports.service';
import { getDateByDateFormat } from 'src/app/constant/constant-functions';
import * as saveAs from 'file-saver';
import { ExportExcel } from 'src/app/constant/ExportExcel';
import { getDateTime } from 'src/app/constant/globalFunction';

@Component({
    selector: 'app-custom-report-design',
    templateUrl: './custom-report-design.component.html',
    styleUrls: ['./custom-report-design.component.css']
})

export class CustomReportDesignComponent implements OnInit {


    addFieldFormGroup: FormGroup<AddFieldForm> = new FormGroup<AddFieldForm>({
        id: new FormControl(-1, { nonNullable: true }),
        name: new FormControl('', { nonNullable: true }),
        generatedBy: new FormControl('', { nonNullable: true }),
        visit: new FormControl(),
        form: new FormControl(),
        field: new FormControl('', { nonNullable: true }),
    });

    //Pagination
    pageSize: number = DEFAULT_PAGE_SIZE;
    pageNo: number = DEFAULT_PAGE_NO;
    totalPages: number = 0;
    page: number = 1;
    serialNumber: number = 1;

    // addFieldFormGroup: FormGroup;
    visitFormData: any = [];

    visitDropDownList: Visit[] = [];
    selectedVisitId: any = [];
    visitDropDownSettings: IDropdownSettings = {};

    formDropDownList: Form[] = [];
    selectedFormId: any = [];
    formDropDownSettings: IDropdownSettings = {};

    fieldDropDownList: Field[] = [];
    selectedFeildId: any = [];
    fieldDropDownSettings: IDropdownSettings = {};

    allFormList: Form[] = [];
    genertaedTime: string = '';
    isFileSelected: boolean = false;
    exportLoading: boolean = false;
    isDownloadLinkHidden: boolean = true;
    isDownloadLink: boolean = false;
    tableHeaderData: TableHeader[] = [];
    filterJson: FilterJson[] = [];
    paginateData: any[] = [];
    pos: any;
    release: boolean = true;
    title: string = CUSTOM_REPORT;
    isNewReport: boolean = false;
    isPreview: boolean = false;
    loading: boolean = false;
    timerArray: any[] = [];
    studyDateFormat: string = '';
    reportId: number = -1;
    roles: number = -1;
    studyName: string = '';
    reportName: string = '';
    saveUpdateLoader:boolean = false

    constructor(
        public renderer2: Renderer2, private formAPI: FormService, private fieldAPI: FieldService, private exportData: ExportExcel,
        private customReportAPI: CustomReportService, private reportAPI: ReportsService, private toastr: ToastrService, private route: ActivatedRoute,
        private location: Location, private router: Router
    ) { }

    ngOnInit() {
        this.getState();
        if (this.isNewReport) this.fillStaticTableHeadings();
        this.multiselect();
        if (this.isPreview == false) {
            this.getVisitFormData('');
            this.populateFormDropdown();
        }
        this.getStorageData();
    }

    getStorageData() {
        this.studyName = sessionStorage.getItem('studyName') ?? '';
        this.roles = Number.parseInt(sessionStorage.getItem("role") ?? '-1');
        this.studyDateFormat = sessionStorage.getItem("studyDateFormat") ?? '';
    }

    fillStaticTableHeadings() {
        ["Site", "Subject", "Visit", "Form"].forEach((label: string, idx: number) => this.tableHeaderData.push(new TableHeaderDTO('', '', idx + 1, label)));
    }

    getState() {
        let state = this.location.getState();
        if (state && typeof state === 'object' && 'id' in state && typeof state.id === 'number' && 'edit' in state && typeof state.edit === 'boolean') {
            if (state.id) {
                if (state.edit) {
                    this.isPreview = false;
                } else {
                    this.isPreview = true;
                }
                this.getCustomReportById(state.id);
            } else {
                this.isNewReport = true;
            }
        } else {
            this.isNewReport = true;
        }
    }

    getCustomReportById(id: number) {
        this.customReportAPI.getCustomReportById(id).subscribe((res) => {
            if (res.status == 0) {
                this.filterJson = res.responseObject.filterJson ?? [];
                this.addFieldFormGroup.controls.id.setValue(res.responseObject.id);
                this.addFieldFormGroup.controls.name.setValue(res.responseObject.name ?? "");
                this.reportName = res.responseObject.name;
                if (this.filterJson != undefined && this.filterJson.length > 0) {
                    this.fillStaticTableHeadings();
                    this.filterJson.forEach((fj: FilterJson) => this.tableHeaderData.push(new TableHeaderDTO(fj.formId, fj.fieldId, fj.orderId, '?column_heading')));
                    this.getCustomReportTableData(this.filterJson);
                }
                this.getReportData(null);
            } else {
                this.toastr.error(res.message);
            }
        }, err => {
            this.toastr.error(err);
        });
    }

    getCustomReportTableData(filterJson: FilterJson[]) {
        this.customReportAPI.getCustomReportData(filterJson, this.pageNo, this.pageSize).subscribe(res => {
            if (res.status == 0) {
                res.responseObject.header.forEach((label: string, idx: number) => this.tableHeaderData[idx].label = label ?? '');
                if (res.responseObject.data && res.responseObject.data.length > 0) {
                    this.paginateData = res.responseObject.data;
                    this.totalPages = res.responseObject.totalCount;
                    this.filterJson = filterJson;
                }
            } else {
                this.toastr.error(res.message);
            }
        }, err => {
            this.toastr.error(err);
        });
    }

    dropCol(event: CdkDragDrop<TableHeader[]>) {
        if (event.currentIndex > 3) moveItemInArray(this.tableHeaderData, event.previousIndex, event.currentIndex);
    }

    mouseDown(event: { target: any; clientX: number; clientY: number; }, el: any = null) {
        el = el || event.target
        this.pos = {
            x: el.getBoundingClientRect().left - event.clientX + 'px',
            y: el.getBoundingClientRect().top - event.clientY + 'px',
            width: el.getBoundingClientRect().width + 'px'
        }
    }

    addOrUpdateCustomReport() {
        if (this.addFieldFormGroup.value.name == "") {
            this.toastr.warning('Report Name is required');
            return;
        }
        let filterJson: FilterJson[] = [];
        this.tableHeaderData.filter(t => this.tableHeaderData.indexOf(t) > 3).forEach(t => filterJson.push(new FilterJsonDTO(t.formId, t.fieldId, this.tableHeaderData.indexOf(t) + 1)));
        if (filterJson.length < 1) {
            this.toastr.warning("Please add at least one column");
            return;
        }
        if (this.addFieldFormGroup.value.id != undefined && this.addFieldFormGroup.value.id != -1) {
            let data = {
                "id": this.addFieldFormGroup.value.id,
                "name": this.addFieldFormGroup.value.name,
                "filterJson": filterJson,
            };
            this.updateCustomReport(data);
        } else {
            let data = {
                "name": this.addFieldFormGroup.value.name,
                "filterJson": filterJson,
            };
            this.createCustomReport(data);
        }
    }

    createCustomReport(data: any) {
        this.saveUpdateLoader = true
        this.customReportAPI.CreateNewCustomReport(data).subscribe(res => {
            if (res.status == 0) {
                this.toastr.success(res.message);
                this.isNewReport = false;
                this.reportName = res.responseObject.name;
                this.addFieldFormGroup.controls.id.setValue(res.responseObject.id);
                if (res.responseObject.filterJson.length != undefined && res.responseObject.filterJson.length > 0) {
                    this.filterJson = res.responseObject.filterJson;
                    this.getCustomReportTableData(this.filterJson);
                }
            } else {
                this.toastr.error(res.message);
            }
            this.saveUpdateLoader = false
        },error => {
            this.saveUpdateLoader = false
        });
    }

    updateCustomReport(data: any) {
        this.saveUpdateLoader = true
        this.customReportAPI.UpdateCustomReport(data).subscribe(res => {
            if (res.status == 0) {
                this.toastr.success(res.message);
                if (res.responseObject.filterJson.length != undefined && res.responseObject.filterJson.length > 0) {
                    this.filterJson = res.responseObject.filterJson;
                    this.getCustomReportTableData(this.filterJson);
                }
            } else {
                this.toastr.error(res.message);
            }
            this.saveUpdateLoader = false
        },error => {
            this.saveUpdateLoader = false
        });
    }

    getReportHistoryById() {
        this.isDownloadLink = true
        this.toastr.success(DOWNLOAD_REPORT_STARTED)
        this.reportAPI.getReportHistoryById(this.reportId).subscribe((res: any) => {
            if (res.status == 0) {
                saveAs(this.exportData.base64ToBlob(res.responseObject, EXPORT_TYPES[0].name), getDateTime(this.reportName, this.roles, this.studyName))
            } else if (res.status == 401) {
                if (this.timerArray.length > 0) {
                    for (let index = 0; index < this.timerArray.length; index++) {
                        clearInterval(this.timerArray[index])
                    }
                }
            }
            this.isDownloadLink = false
        }, err => {
            if (this.timerArray.length > 0) {
                for (let index = 0; index < this.timerArray.length; index++) {
                    clearInterval(this.timerArray[index])
                }
                this.loading = false
            }
            this.isDownloadLink = false
        })
    }

    multiselect() {
        this.visitDropDownList = [];
        this.visitDropDownSettings = {
            singleSelection: true,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'Unselect',
            allowSearchFilter: true,
        };

        this.formDropDownList = [];
        this.formDropDownSettings = {
            singleSelection: true,
            idField: "formId",
            textField: "formName",
            selectAllText: 'Select All',
            unSelectAllText: 'Unselect',
            allowSearchFilter: true,
        };

        this.fieldDropDownList = [];
        this.fieldDropDownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'label',
            selectAllText: 'Select All',
            unSelectAllText: 'Unselect',
            itemsShowLimit: 2,
            allowSearchFilter: true,
        };
    }

    onSelectForm() {
        this.addFieldFormGroup.controls.field.reset();
        this.getFieldsByFormId(this.addFieldFormGroup.value.form);
    }

    deSelectVisit() {
        this.addFieldFormGroup.controls.form.reset();
        let visitData: any = this.addFieldFormGroup.controls.visit?.value;
        if (visitData.length == 0) {
            this.visitDropDownList = this.extractVisits(this.visitFormData['visit'])
        }
    }

    onSelectVisit() {
        this.addFieldFormGroup.controls.form?.reset();
    }

    getFieldsByFormId(form: any) {
        if (form) {
            this.fieldAPI.getFieldsByForm(form[0].formId).subscribe(res => {
                this.fieldDropDownList = res.responseObject.filter((field: { dataTypeId: number }) => field.dataTypeId != 17 && field.dataTypeId != 18);
                console.log(res);
            });
        }
    }

    addNewField(form: any, fields: any) {
        if (fields) fields.forEach((fi: { id: any; label: any; }) => this.addFieldInHeader(fi, form));
        this.addFieldFormGroup.controls.field.reset();
    }

    private addFieldInHeader(fi: { id: any; label: any; }, form: any) {
        this.tableHeaderData.push(new TableHeaderDTO(form[0]?.formId ?? '', fi.id ?? '', 0, fi.label ?? ''));
    }

    extractVisits(visits: any): any[] {
        let visitsArray: any[] = [];
        let formsArray: Form[] = [];
        Object.keys(visits).map((key) => {
            visitsArray.push({ id: key, name: visits[key].name });
            visits[key].forms.map((key: any, value: any) => {
                formsArray.push(key);
            })
        });
        //this.visitsArray.push({id:DEFAULT_UUID,name:DEFAULT_VISIT_NAME});
        this.allFormList = formsArray;
        return visitsArray;
    }

    populateFormDropdown() {
        this.addFieldFormGroup.controls.visit?.valueChanges.pipe(distinctUntilChanged()).subscribe((visit) => {
            this.selectedFormId = visit;
            this.formDropDownList = this.extractForms(toArray(visit));
        });
    }

    extractForms(visits: Visit[]): any[] {
        let formsArray: any[] = [];
        if (visits && visits.length > 0) {
            visits.map((item) => {
                const formIdKey = item.id;
                const formDetails = this.visitFormData['visit'][formIdKey].forms;
                formsArray.push(...formDetails);
            });
        }
        return formsArray;
    }

    getVisitFormData(subjectId: any) {
        forkJoin({
            formData: this.formAPI.getVisitFormData(subjectId),
            logData: this.formAPI.getLogFormAPIData()
        }).subscribe(res => {
            let formData = res.formData.responseObject;
            let logsData = res.logData.responseObject;
            Object.assign(formData.visit, logsData.visit);
            const visit = formData['visit'];
            this.visitFormData = formData;
            this.visitDropDownList = this.extractVisits(visit);
        })
    }

    remove(column: any) {
        this.tableHeaderData.splice(this.tableHeaderData.indexOf(column), 1);
    }

    formatInput(input: HTMLInputElement) {
        const FILTER_PAG_REGEX = /[^0-9]/g;
        input.value = input.value.replace(FILTER_PAG_REGEX, '');
    }

    selectPage(page: string) {
        this.page = parseInt(page, 10) || 1;
        this.pageNo = this.page - 1
        // this.getAllCustomReports()
    }

    onChangePagination() {
        this.pageNo = this.page - 1
        this.serialNumber = this.page == 1 ? 1 : (this.page - 1) * 10 + 1;
        this.getCustomReportTableData(this.filterJson);
    }

    previewWithData() {
        let filterJson: FilterJson[] = [];
        this.tableHeaderData.filter(t => this.tableHeaderData.indexOf(t) > 3).forEach(t => filterJson.push(new FilterJsonDTO(t.formId, t.fieldId, this.tableHeaderData.indexOf(t) + 1)));
        if (filterJson.length < 1) {
            this.toastr.warning("Please add at least one column");
        } else {
            this.getCustomReportTableData(filterJson);
        }
    }

    close() {
        this.router.navigate(['/layout/custom-report']);
    }

    exportExcel() {
        if (this.addFieldFormGroup.value.id && this.addFieldFormGroup.value.id != -1) {
            this.customReportAPI.exportCustomReportData(this.addFieldFormGroup.value.id).subscribe((res) => {
                if (res.status == 0) {
                    this.loading = true;
                    this.toastr.success(res.message);
                    this.isDownloadLinkHidden = true;
                    let timerId: any = setInterval(() => this.getReportData(timerId), REPORT_INTERVAL_START_TIME);
                    this.timerArray.push(timerId);
                } else {
                    this.toastr.error(res.message);
                }
            }, error => {
                this.toastr.error("Error while exporting");
            });
        } else {
            this.toastr.warning('Please first save the custom report');
        }
    }

    getReportData(timerId: any) {
        // if(this.studyId){
        let reportName = REPORTS_NAME[20].name.concat(`_${this.addFieldFormGroup.value.id}`);
        this.reportAPI.getReportHistory(reportName).subscribe((res) => {
            if (res.responseObject != undefined || res.responseObject != null) {
                if (!res.responseObject.inProgress) {
                    clearInterval(timerId);
                    console.log(res, res.responseObject.reportId);
                    this.genertaedTime = getDateByDateFormat(res.responseObject.lastImported, this.studyDateFormat);
                    if (res.responseObject.reportId) {
                        this.isDownloadLinkHidden = false;
                        this.reportId = res.responseObject.reportId;
                        this.loading = false;
                    } else {
                        this.isDownloadLinkHidden = true;
                    }
                }
            }
        }, err => {
            if (this.timerArray.length > 0) {
                for (let index = 0; index < this.timerArray.length; index++) {
                    clearInterval(this.timerArray[index]);
                }
                this.loading = false;
            }
        });
    }
}

interface AddFieldForm {
    id: FormControl<number>;
    name: FormControl<string>;
    generatedBy: FormControl<string>;
    visit: FormControl<Visit | undefined>;
    form: FormControl<Form | undefined>;
    field: FormControl<string>;
}

interface Visit {
    id: string;
    name: string;
}

interface Form {
    formId: string;
    formName: string;
}

interface Field {
    id: string;
    label: string;
}
