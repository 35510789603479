<br>
<form *ngIf="validationAndLogMapping" [formGroup]="validationAndLogMapping">
    <div class="row">
        <div class="col-10">
            <div class="form-floating mb-3">
                <input type="text" class="form-control" formControlName="name" autocomplete="off"
                id="name" name="name" placeholder="Enter Validation Name" max="500" maxlength="500">
                <label for="name" *ngIf="tabName == 'validation'">Validation Name</label>
                <label for="name" *ngIf="tabName == 'logMapping'">Log Mapping Name</label>
                <!-- <label for="name">Validation Name</label> -->
                <i><b>Note:</b> Maximium character allowed 500</i>
            </div>
        </div>
        <div class="col-2 text-left" *ngIf="validationAndLogMapping.get('name')?.value != undefined">
            <button title="Add Validation" class="btn btn-outline-primary mt-2" title="Add New Validation" (click)="addValidationOrLogMapping(false)">
                <span *ngIf="tabName == 'validation'">Add Validation</span>
                <span *ngIf="tabName == 'logMapping'">Add Log Mapping</span>
            </button>
        </div>
    </div>
    <!-- Rule Key -->
    <div formArrayName="rule">
        <div *ngFor="let item of getValidationOrLogMappingRuleKey.controls;let i=index">
            <div class="row" [formGroupName]="i">
                <div class="col-12" *ngIf="getSelectedRuleKeyValue('tabName',i) == tabName">
                    <input type="hidden" name="id" formControlName="ruleId">
                    <!-- Accordion Design -->
                    <div ngbAccordion>
                        <div ngbAccordionItem>
                            <div ngbAccordionHeader>
                                <button class="accordion-button control-buttons" ngbAccordionToggle>
                                    <div class="col-10 wordWrap">
                                        <b *ngIf="getSelectedRuleKeyValue('isNameEditable',i) == false">
                                            {{getSelectedRuleKeyValue('name',i)}}
                                        </b>
                                        <input type="text" name="validationName"
                                        id="validationName" formControlName="editedName" max="500" maxlength="500"
                                        *ngIf="getSelectedRuleKeyValue('isNameEditable',i) == true">
                                        
                                        <button class="btn btn-outline-secondary input-group-addon" *ngIf="getSelectedRuleKeyValue('isNameEditable',i) == true" (click)="onValidationOrLogMappingEditName(i,false)">
                                            <b>OK</b>
                                        </button>
                                        
                                        <button class="control-buttons" title="Edit" (click)="onValidationOrLogMappingEditName(i,true)" [hidden]="isLocked == 1 || roles != 1">
                                            <span class="editIcon"></span>
                                        </button>
                                        
                                    </div>
                                    <div class="col mt-2">
                                        <span class="slideToggle" [hidden]="roles != 1">
                                        <mat-slide-toggle (change)="enableDisableValidationOrLogMapping($event,getSelectedRuleKeyValue('status',i),getSelectedRuleKeyValue('id',i),i,isTabValidation)" [checked]="getSelectedRuleKeyValue('status',i) == 1 ? true : false" [title]="getSelectedRuleKeyValue('status',i) == 1 ? 'Enable':'Disable'" color="primary">
                                            </mat-slide-toggle>
                                        </span>
                                        <button class="control-buttons" title="Save" (click)="addSingleValidation(item,i)" *ngIf="getSelectedRuleKeyValue('id',i) == null" [hidden]="isLocked == 1 || roles != 1">
                                            <span class="saveIcon"></span>
                                        </button>
                                        <button class="control-buttons" title="Save" (click)="addSingleValidation(item,i)" *ngIf="getSelectedRuleKeyValue('id',i) != null" [hidden]="isLocked == 1 || roles != 1">
                                            <span class="saveIcon"></span>
                                        </button>
                                        <button class="control-buttons" title="Remove Rule" (click)="removeValidationOrLogMappingRule(i,isTabValidation)" [hidden]="isLocked == 1 || roles != 1">
                                            <span class="deleteIcon"></span>
                                        </button> 
                                    </div>
                                </button>
                            </div>
                            <div ngbAccordionCollapse>
                                <div ngbAccordionBody>
                                    <ng-template>
                                        <validation-rule-condition [formRuleValidation]="validationAndLogMapping" [fetchedDataTypeId]="fetchedDataTypeId" [formType]="formType" [ruleLength]="i" [tabName]="tabName" [fetchedVisitId]="fetchedVisitId" [fetchedFormId]="fetchedFormId" [fetchedFieldId]="fetchedFieldId" [fetchValidationData]="fetchValidationData" [visitListMainData]="visitListMainData" [visitListData]="visitListData" [logVisitListMainData]="logVisitListMainData" [logVisitListData]="logVisitListData"></validation-rule-condition>
                                        <div class="row">
                                            <div class="col text-end">
                                                <button class="btn btn-outline-success" title="Save" [hidden]="roles == readOnlyRole" (click)="addSingleValidation(item,i)" *ngIf="getSelectedRuleKeyValue('id',i) == null" [disabled]="getSelectedRuleKeyValue('isLoading',i)">
                                                    <b>
                                                        Save <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="getSelectedRuleKeyValue('isLoading',i)"></span>
                                                    </b>
                                                </button>
                                                <button class="btn btn-outline-success" title="Save" [hidden]="roles == readOnlyRole" (click)="addSingleValidation(item,i)" *ngIf="getSelectedRuleKeyValue('id',i) != null" [disabled]="getSelectedRuleKeyValue('isLoading',i)">
                                                    <b>
                                                        Save <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="getSelectedRuleKeyValue('isLoading',i)"></span>
                                                    </b>
                                                </button>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Accordion Design Ended -->
                    <br>
                </div>
            </div>
        </div>
    </div>
    <!-- Rule Key Ended -->
</form>