import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseURL, VERSION } from '../constant/constant';

@Injectable({
  providedIn: 'root'
})
export class UnitService {

  constructor(private http:HttpClient) { }

  getAllUnits():Observable<any>{
    return this.http.get(`${baseURL}units/${VERSION}/list`)
  }

  saveUnits(payload:any):Observable<any>{
    return this.http.post(`${baseURL}units/${VERSION}`,payload);
  }

  updateUnit(payload:any):Observable<any>{
    return this.http.put(`${baseURL}units/${VERSION}`,payload);
  }

  
  deleteUnit(unitId: string):Observable<any>{
    return this.http.delete(`${baseURL}units/${VERSION}?id=${unitId}`);
  }


  getUnitById(unitId: string): Observable<any>{
    return this.http.get(`${baseURL}units/${VERSION}?id=${unitId}`);
  }}
