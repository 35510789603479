import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { baseURL, basicAuth, loginAPI, logOutAPI, ROLES, VERSION } from '../constant/constant';
import { LoginService } from './login.service';
import { getTokenData } from '../constant/globalFunction';
import { Role } from '../models/Role';

@Injectable({
  providedIn: 'root'
})
//TODO : Need to revisit whole code
export class AuthService implements HttpInterceptor{
  
  isRefreshing:boolean = false

  dashboardEndpoint:any = `${baseURL}dashboard/${VERSION}/`
  
  formListEndpoint:any = `${baseURL}forms/${VERSION}/list`

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private router:Router,private toastr:ToastrService,private api:LoginService) { }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let study = sessionStorage.getItem("studyId") == null ? '' : sessionStorage.getItem("studyId")
    let role = sessionStorage.getItem("role") == null ? '' : sessionStorage.getItem("role")
    let userName = sessionStorage.getItem("userName")
    let studyNullEndpoints:any[] = [
      `${baseURL}study/${VERSION}/studyIdNameList`,
      `${baseURL}study/${VERSION}/studySiteList`,
      `${baseURL}auditlog/${VERSION}/userlog/list`
    ]

    if(req.url.match(loginAPI)){
      req = req.clone({
        setHeaders:{
          Authorization: `Basic `+basicAuth,
        }
      })
    }else if(req.url.match(logOutAPI)){
      req = req.clone({
        setHeaders:{
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          'studyId':`${study}`,
          'roleId':`${role}`,
        }
      })
    }else if(( studyNullEndpoints.includes(req.url) ) && userName == 'admin' ){
      req = req.clone({
        setHeaders:{
          Authorization: `Bearer ${getTokenData("token")}`,
          'studyId':``,
          'roleId':`${role}`
        }
      })
    }else if((req.url.includes(`${baseURL}subjects/${VERSION}/subjectIdList`)) && userName == 'admin'){
      let localStudyId = localStorage.getItem("tempStudyId") == null ? '':localStorage.getItem("tempStudyId")
      req = req.clone({
        setHeaders:{
          Authorization: `Bearer ${getTokenData("token")}`,
          'studyId':`${localStudyId}`,
          'roleId':`${Role.SUPER_ADMIN}`
        }
      })
    }else if((req.url.includes(`${baseURL}`)) && userName == 'admin'){
      let localStudyId = localStorage.getItem("tempStudyId") == null ? '':localStorage.getItem("tempStudyId")
      req = req.clone({
        setHeaders:{
          Authorization: `Bearer ${getTokenData("token")}`,
          'studyId':`${localStudyId}`,
          'roleId':`${role}`
        }
      })
    }else if(req.url.includes(`${baseURL}users/${VERSION}/changePassword`)){
      req = req.clone({
        setHeaders:{
          Authorization: `Bearer ${getTokenData("token")}`
        }
      })
    }else{
      req = req.clone({
        setHeaders:{
          Authorization: `Bearer ${getTokenData("token")}`,
          'studyId':`${study}`,
          'roleId':`${role}`
        }
      })
    }

    return next.handle(req).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        console.log("401 recived");
        if(req.url.match(loginAPI)){
          this.router.navigate([''])
          // this.toastr.info(SESSION_LOGOUT)
          return throwError(error);
        }else if(req.url.match(logOutAPI)){
          this.router.navigate([''])
          sessionStorage.clear()
          localStorage.clear()
          // this.toastr.info(SESSION_LOGOUT)
          return throwError(error);
        }else if(req.url.includes(`${baseURL}users/${VERSION}/resendPassword`)){
          sessionStorage.clear()
          localStorage.clear()
          return throwError(error);
        }
        return this.handle401Error(req, next);
      }
      return throwError(error);
    }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const token = sessionStorage.getItem("refresh_token");
      if (token) {
        let data = "grant_type=refresh_token&refresh_token="+token;
        return this.api.loginWithRefreshToken(data).pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            sessionStorage.setItem("token",token.access_token)
            this.refreshTokenSubject.next(token.access_token);
            
            return next.handle(this.addTokenHeader(request, token.access_token));
          }),
          catchError((err) => {
            console.log("401 referesh token recived");
            
            this.isRefreshing = false;
            sessionStorage.clear();
            localStorage.clear()
            return throwError(err);
          })
        );
      }
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }
  
  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ 
      setHeaders:{
        Authorization: `Bearer `+token,
      } 
    });
  }
}
