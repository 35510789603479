<!-- <p>login works!</p> -->
<div class="container d-flex justify-content-center">
  <div class="row">
    <div class="jumbotron jumbotron-fluid">
      <div class="col">
        <form *ngIf="formGroup" [formGroup] = "formGroup" (ngSubmit) = "loginProcess()">
          <div class="card mt-5">
            <div class="card-header">
              <div class="display-5">
                Login to EDC
              </div>
            </div>
            <div class="card-body">
              <div class="form-floating mb-3">
                <!-- Username -->
                <input type="text" class="form-control"
                name="userName" id="userName" formControlName="username"
                placeholder="Enter UserName" autofocus tabindex="1">
                <label for="forUserName">Username</label>
              </div>
              <!-- Password -->
              <div class="form-floating">
                  <input [type]="type" class="form-control"
                  name="password" id="password" formControlName="password"
                  placeholder="Password" tabindex="1">
                  <label for="forPassword">Password</label>
                  <!-- <span class="showPassword view-logo" (click)="isVisiblePassword = true; type='text'" *ngIf="!isVisiblePassword"></span>
                  <span class="showPasswordView view-logo" (click)="isVisiblePassword = false; type='password'" *ngIf="isVisiblePassword"></span> -->
              </div>
              <div class="form-floating text-end">
                <a href="#" [routerLinkActive]="['router-link-active']" [routerLink]="['/forgotPassword']" [ariaDisabled]="loading"><b>Forgot Password?</b></a>
              </div>
              <app-captcha [config]="captchaConfig" formControlName="captchaData"/>
            </div>
            <!-- Footer -->
            <div class="card-footer text-center">
              <button type="submit" class="btn btn-outline-success" tabindex="1" [disabled]="loading">
                <b>
                  Submit <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
                </b>
              </button>&nbsp;&nbsp;
              <button type="reset" class="btn btn-outline-danger" [disabled]="loading"><b>Reset</b></button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="text-center p-lg-5">
  <!-- Word Beta is removed as mentioned in EDC-219  Beta to remove -->
  <p> © 2024 EDC. All rights reserved. <span> v1.0.0</span></p>
</div>

<!-- <section class="bg-login-form-image">
  <div class="loginFormBox container">
    <div class="row">
      <form *ngIf="formGroup" [formGroup] = "formGroup" (ngSubmit) = "loginProcess()">
        <div>
          <div>
            <div class="edcFontStyle">
              EDC
            </div>
          </div>
          <div class="loginFontStyle">
            Login
          </div>
          <div class="inputBox">
            <div>
                <! -- Username -- >
              <input type="text"
              name="userName" id="userName" formControlName="username"
              placeholder="Username" autofocus tabindex="1" class="login-input">
              <span class="loginUserIcon"></span>
            </div>
            <! -- Password -- >
            <div>
                <input [type]="type"
                name="password" id="password" formControlName="password"
                placeholder="Password" tabindex="1" class="login-input">
                <span class="lockIcon"></span>
                <! -- <span class="showPassword view-logo" (click)="isVisiblePassword = true; type='text'" *ngIf="!isVisiblePassword"></span>
                <span class="showPasswordView view-logo" (click)="isVisiblePassword = false; type='password'" *ngIf="isVisiblePassword"></span> -- >
            </div>
            <div>
              <a href="#" [routerLinkActive]="['router-link-active']" [routerLink]="['/forgotPassword']" class="forgotPasswordLink"><b>Forgot Password?</b></a>
            </div>
            <app-captcha [config]="captchaConfig" formControlName="captchaData" />
          </div>
          <! -- Footer -- >
          <div class="buttonPosition">
            <button class="btn btn-success buttonStyle" type="submit" >
              <b>
                Submit <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
              </b>
            </button>&nbsp;&nbsp;
            <button type="reset" class="btn btn-primary buttonStyle"><b>Reset</b></button>
          </div>
        </div>
      </form>
    </div>
  </div> 
</section>


 -->
