export enum Role {
    SUPER_ADMIN = 0,
    Programer = 1, //1 - study, visit, form, formula
    CRC = 2, //2 - data-entry, subject
    CRA = 3, //3 - data-entry, subject
    DM = 4, //4 - data-entry, subject
    Investigator = 5, //5 - data-entry, subject
    admin = 6, //6 - all
    // Monitor = 7,
    ReadOnly = 7,
}
