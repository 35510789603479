<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title"><span *ngIf="extendedTitle">{{extendedTitle}} </span>Reason</h5>
        <button class="btn-close" data-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="reasonForm">
            <div class="row">
                <div class="col-12">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" formControlName="reason" id="reason" autocomplete="off"
                        name="reason" placeholder="Enter Current Password" autocomplete="off" maxlength="255"/>
                        <label for="reason">Reason</label>
                        <i><b>Note:</b> Maximium character allowed 255</i>
                    </div>
                </div>
            </div>
        </form>
        <div class="modal-footer">
            <div class="container">
                <div class="row">
                    <div class="col-8 mt-1">
                        <p [style.color]="blackColor">
                            {{reasonMessage}}
                        </p>
                    </div>
                    <div class="col-4 text-end">
                        <button type="button" id="cancel" class="btn btn-outline-danger" data-bs-dismiss="modal" (click)="closeModal()">
                            <b>Cancel</b>
                        </button>
                        &nbsp;
                        <button class="btn btn-outline-primary" (click)="applyReason()" [hidden]="isLocked == 1">
                            <b>
                                Ok <span class="spinner-border spinner-border-sm" *ngIf="reasonLoading" role="status" aria-hidden="true"></span>
                            </b>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>