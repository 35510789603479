import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { SELECT_FORM_MSG, SUBJECT_SELECT_MSG, VISIT_NAME_ERROR_MSG, VISIT_SELECT_MSG } from 'src/app/constant/responseMessage';
import { DataEntryService } from 'src/app/services/data-entry.service';
import { FormService } from 'src/app/services/form.service';
import { VisitsService } from 'src/app/services/visits.service';

@Component({
  selector: 'app-unschedule-visit-popup',
  templateUrl: './unschedule-visit-popup.component.html',
  styleUrls: ['./unschedule-visit-popup.component.css']
})
export class UnscheduleVisitPopupComponent implements OnInit{
  
  @Input() rowData:any

  unScheduleVisitForm:FormGroup
  
  visitList:any[] = []

  //Form Selection Box
  formDropdownSettings:IDropdownSettings = {}
  formsList:any[] = []

  //Table Data
  tableData:any

  saveLoading:boolean = false

  constructor(private modalService:NgbModal,private formAPI:FormService,private visitAPI:VisitsService,
    private toastr:ToastrService,private formBuilder:FormBuilder,private dataEntryAPI:DataEntryService){
    this.unScheduleVisitForm = formBuilder.group({
      name:new FormControl(),
      orderId:new FormControl(),
      subjectId:new FormControl(),
      formIds:[],
    })
  }
  
  ngOnInit(): void {
    this.getVisitData()
    this.getFormData()
    this.setDropDownData()
  }

  getVisitData(){
    this.visitAPI.getVisitsBySubjectId('').subscribe(res=>{
      console.log("RESPONSE :: ",res);
      this.visitList = res.responseObject
    })
  }

  getFormData(){
    this.formAPI.getAllDataStudyWise().subscribe(res=>{
      console.log("RESPONSE :: ",res);
      // this.formsList = res.responseObject
      if(res.responseObject){
        let jsonArray:any = []
        for (let index = 0; index < res.responseObject.length; index++) {
          let jsonData:any = {
            formId:res.responseObject[index].formId,
            formName:res.responseObject[index].formName + " - (" + res.responseObject[index].visitName + ")"
          }
          jsonArray.push(jsonData)
        }
        this.formsList = jsonArray
      }
    })
  }

  setDropDownData(){
    this.formDropdownSettings = {
      singleSelection: false,
      idField: 'formId',
      textField: 'formName',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect',
      itemsShowLimit: 2,
      allowSearchFilter: true,
    };
  }

  onVisitSelected(event:any){}
  
  onUnSelectForm(){}
  onSelectForm(){}

  closeModal(){
    this.modalService.dismissAll()
  }

  validateUnScheduleVisit(){
    if(this.unScheduleVisitForm.get("name")?.value == undefined || this.unScheduleVisitForm.get("name")?.value == null || this.unScheduleVisitForm.get("name")?.value == ''){
      this.toastr.warning(VISIT_NAME_ERROR_MSG)
      return false
    }else if(this.unScheduleVisitForm.get("orderId")?.value == undefined || this.unScheduleVisitForm.get("orderId")?.value == null || this.unScheduleVisitForm.get("orderId")?.value == ''){
      this.toastr.warning(VISIT_SELECT_MSG)
      return false
    }else if((this.unScheduleVisitForm.get("formIds") as FormArray).value == null){
      this.toastr.warning(SELECT_FORM_MSG)
      return false
    }
    return true
  }
  
  saveUnScheduleVisit(){
    if(this.validateUnScheduleVisit()){
      let resquestData:any = {
        name:this.unScheduleVisitForm.get("name")?.value,
        orderId:this.unScheduleVisitForm.get("orderId")?.value
      }
      if((this.unScheduleVisitForm.get("formIds") as FormArray).length != 0){
        let formIdData = (this.unScheduleVisitForm.get("formIds") as FormArray).value
        let formIds:any[] = []
        if(formIdData && formIdData.length > 0){
          for (let index = 0; index < formIdData.length; index++) {
            formIds.push(formIdData[index].formId)
          }
        }
        resquestData['formIds'] = formIds
      }
      this.saveLoading = true
      this.dataEntryAPI.saveUnScheduleVisitData(this.rowData.id,resquestData).subscribe(res => {
        console.log("RESPONSE :: ",res,resquestData);
        if(res.status == 0){
          this.toastr.success(res.message)
          this.closeModal()
          this.saveLoading = false
        }else{
          this.toastr.error(res.message)
          this.saveLoading = false
        }
      })
    }
  }
}
